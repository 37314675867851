import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import s from './Button.module.scss';

const Button = ({
    children,
    title,
    className,
    modifier,
    modifiers,
    url,
    target,
    rel,
    attrTitle,
    type,
    disabled,
    full,
    onClick,
    atts,
}) => {
    const isButton = _.isEmpty(url);

    const Tag = isButton ? 'button' : 'a';
    const defaultType = isButton && _.isEmpty(type) ? 'button' : type;

    const classes = classNames(
        className,
        s['Button'],
        {[s['Button--Disabled']]: disabled},
        {[s['Button--Full']]: full},
        {[s[`Button--${modifier}`]]: modifier},
        modifiers.map((m) => [s[`Button--${m}`]]),
    );

    return (
        <Tag
            className={classes}
            href={url}
            target={target}
            rel={rel}
            title={attrTitle}
            type={defaultType}
            disabled={disabled}
            onClick={onClick}
            {...atts}
        >
            {children || title}
        </Tag>
    );
};

Button.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.elementType,
    ]),
    className: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string,
    attrTitle: PropTypes.string,
    type: PropTypes.string,
    modifier: PropTypes.string,
    modifiers: PropTypes.array,
    disabled: PropTypes.bool,
    full: PropTypes.bool,
    onClick: PropTypes.func,
    atts: PropTypes.object,
};

Button.defaultProps = {
    children: '',
    title: '',
    url: null,
    target: null,
    rel: null,
    attrTitle: null,
    type: null,
    modifier: '',
    modifiers: [],
    disabled: null,
    full: false,
    onClick: null,
    atts: {},
};

export default Button;
