import React, {useEffect, createContext} from 'react';
import PropTypes from 'prop-types';
import {ErrorBoundary} from 'react-error-boundary';
import LazyContainers from '../containers/LazyContainers';

const ErrorFallback = ({
    error = {},
    isDebug = false,
    ...restProps
}) => {
    // Handle failed lazy loading of a JS/CSS chunk.
    useEffect(() => {
        console.error(error);
    }, [error]);

    return (
        <div>
            <p>Something went wrong.</p>
            {isDebug &&
                <pre>{error?.message}</pre>
            }
        </div>
    );
};

ErrorFallback.propTypes = {
    error: PropTypes.object,
    isDebug: PropTypes.bool,
};

const AppContext = createContext({
    isSSR: false,
    isStorybook: false,
    isDebug: false,
    containerName: '',
});

const App = ({
    containerName = '',
    ...restProps
}) => {
    const isDebug = process?.env?.NODE_ENV === 'development';
    const Container = LazyContainers[containerName];
    if (!Container) {
        return null;
    }

    const settings = {
        isSSR: typeof window === 'undefined',
        isStorybook: false,
        isDebug: isDebug,
        containerName: containerName,
    };

    return (
        <ErrorBoundary
            FallbackComponent={(fallbackProps) => (
                <ErrorFallback
                    {...fallbackProps}
                    isDebug={isDebug}
                />
            )}
        >
            <AppContext.Provider value={settings}>
                <Container
                    containerName={containerName}
                    {...restProps}
                />
            </AppContext.Provider>
        </ErrorBoundary>
    );
};

App.propTypes = {
    containerName: PropTypes.string,
};

export {AppContext};

export default App;
