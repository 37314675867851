/* global jQuery */
import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import {sanitizeText, sanitizeBasicHTML} from 'utils/html';
import {getCookie, setCookie} from 'utils/cookie';
import Gravityforms from 'Components/Gravityforms';
import s from './Popup.module.scss';

const Popup = ({
    identifier = 'popup',
    title = '',
    richtext = '',
    form = '',
    formId = 0,
    timeout = 0,
    defaultConfirmation = false,
}) => {
    const {t} = useTranslation();

    const popupRef = useRef();

    const [isOpen, setIsOpen] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(defaultConfirmation);
    const [minHeight, setMinHeight] = useState(0);

    const sanitizedTitle = sanitizeText(title);
    const sanitizedText = sanitizeBasicHTML(richtext);

    const cookieName = `hrf-popup-closed-${identifier}`;

    useEffect(() => {
        if(popupRef && popupRef.current) {
            const height = popupRef.current.clientHeight;
            setMinHeight(height);
        }

        const currentCookie = getCookie(cookieName);
        if(currentCookie && currentCookie === 'true') {
            setIsOpen(false);
        } else if(!isOpen) {
            if(timeout > 0) {
                setTimeout(() => {
                    setIsOpen(true);
                }, timeout);
            } else {
                setIsOpen(true);
            }
        }

        if(typeof jQuery !== 'undefined') {
            jQuery(document).on('gform_confirmation_loaded', function() {
                setShowConfirmation(true);
            });
        }

        return () => {
            if(typeof jQuery !== 'undefined') {
                jQuery(document).off('gform_confirmation_loaded');
            }
        };
    }, []);

    const clickHandler = () => {
        setIsOpen(false);
        setCookie(cookieName, 'true', 0);
    };

    const style = {
        ...((minHeight > 0) && {minHeight: minHeight}),
    };

    const classes = classNames(
        s['Popup'],
        {[s['Popup--Open']]: isOpen},
        {[s['Popup--Closed']]: !isOpen},
        {[s['Popup--Confirmation']]: showConfirmation},
    );

    return (
        <aside
            className={classes}
            id={`popup-${identifier}`}
            aria-expanded={isOpen}
            ref={popupRef}
            style={style}
        >
            <div className={s['Popup__Container']}>
                <div className={s['Popup__Content']}>
                    {!showConfirmation &&
                        <h2
                            className={s['Popup__Title']}
                            dangerouslySetInnerHTML={{__html: sanitizedTitle}}
                        />
                    }

                    {!showConfirmation && !_.isEmpty(richtext) &&
                        <div
                            className={s['Popup__Richtext']}
                            dangerouslySetInnerHTML={{__html: sanitizedText}}
                        />
                    }

                    {!_.isEmpty(form) &&
                        <div className={s['Popup__Form']}>
                            <Gravityforms
                                identifier={`popup-gf-${identifier}`}
                                innerHTML={form}
                                attrs={{
                                    formId: formId,
                                }}
                                onConfirmation={() => setShowConfirmation(true)}
                            />
                        </div>
                    }
                </div>
                <button
                    className={s['Popup__Close']}
                    type="button"
                    aria-label={t('popup.close')}
                    onClick={() => clickHandler()}
                />
            </div>
        </aside>
    );
};

Popup.propTypes = {
    identifier: PropTypes.string,
    title: PropTypes.string,
    richtext: PropTypes.string,
    form: PropTypes.string,
    formId: PropTypes.string,
    timeout: PropTypes.number,
    defaultConfirmation: PropTypes.bool,
};

export default Popup;
