import React, {useEffect, useRef, useState, useContext} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import {AppContext} from 'Layouts/App';
import s from './Snippet.module.scss';

const Snippet = ({html = '', type = ''}) => {
    const {isSSR} = useContext(AppContext);

    const containerRef = useRef();
    const [mounted, setMounted] = useState(false);

    const regex = /<script([\s\S]*?)>([\s\S]*?)<\/script>/gi;
    const htmlExcludedScript = html.replace(regex, '');

    const scripts = [...html.matchAll(regex)].map((match) => {
        const src = /src="(.*)"/gi.exec(match[1]);
        return {
            src: src !== null ? src[1] : '',
            innerHTML: match[2],
        };
    });

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if(!isSSR && mounted) {
            scripts.map(({src, innerHTML}) => {
                const script = document.createElement('script');
                script.type = 'text/javascript';
                if(!_.isEmpty(src)) {
                    script.src = src;
                }
                if(!_.isEmpty(innerHTML)) {
                    script.innerHTML = innerHTML;
                }
                containerRef.current.appendChild(script);
            });
        }
    }, [scripts, mounted, isSSR]);

    const classes = classNames(
        s['Snippet'],
        {[s[`Snippet--${_.upperFirst(type)}`]]: type},
    );

    return (
        <div className={classes}>
            <div className={s['Snippet__Wrap']}>
                <div
                    ref={containerRef}
                    className={s['Snippet__Content']}
                    dangerouslySetInnerHTML={{__html: htmlExcludedScript}}
                />
            </div>
        </div>
    );
};

Snippet.propTypes = {
    html: PropTypes.string,
    type: PropTypes.string,
};

export default Snippet;
