import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import NextImage from 'next/image';
import classNames from 'classnames';
import {useTranslation} from 'next-i18next';
import _ from 'lodash';
import Logo from 'Assets/img/logo-hrf.svg';
import LogoSmall from 'Assets/img/logo-hrf-small.svg';
import Hornet from 'Assets/img/logo-hornet.svg';
import Cart from 'Assets/svg/cart.svg';
import Hamburger from 'Assets/svg/hamburger.svg';
import Intranet from 'Assets/svg/intranet.svg';
// import Language from 'Assets/svg/language.svg';
import Mypages from 'Assets/svg/mypages.svg';
import Search from 'Assets/svg/search.svg';
import Nav from 'Components/Nav';
import s from './Header.module.scss';

const Header = (props) => {
    const {
        currentId,
        parentIds,
        main,
        CTA,
        searchUrl,
        cartUrl,
        cartCount,
        languages,
        mypagesUrl,
        intranetUrl,
        siteUrl,
        mainUrl,
        localCounty,
        localName,
        isLoggedIn,
        isLocal,
        isIntranet,
        myPages,
    } = props;

    const {t} = useTranslation();

    const [mobileMenu, setMobileMenu] = useState(false);
    const [currentMegaId, setCurrentMegaId] = useState(null);
    const [myPagesOpen, setMyPagesOpen] = useState(false);

    let rightTop = [];
    // if(!_.isEmpty(languages)) {
    //     rightTop.push({
    //         url: '',
    //         title: t('header.language'),
    //         Icon: Language,
    //     });
    // }
    if(!_.isEmpty(mypagesUrl)) {
        const myPagesMenu = _.get(myPages, 'children', []).map((c) => {
            return {...c, title: t(`header.myPages.${c.type}`)};
        });
        const isMyPages = !_.isEmpty(myPagesMenu);
        rightTop.push({
            url: mypagesUrl,
            title: t('header.mypages'),
            extraClasses: isMyPages ? ['LoggedIn', 'MyPages'] : [],
            description: isMyPages ? t('header.loggedIn') : t('header.mypagesText'),
            Icon: Mypages,
            forceExpanded: isMyPages && myPagesOpen,
            onClickHandler: (e) => {
                if(isMyPages) {
                    e.preventDefault();
                    setMyPagesOpen(!myPagesOpen);
                }
            },
            ...myPages,
            children: myPagesMenu,
        });
    }
    if(!_.isEmpty(languages)) {
        rightTop.push({
            url: intranetUrl,
            title: t('header.intranet'),
            extraClasses: isLoggedIn ? ['LoggedIn'] : [],
            description: isLoggedIn ? t('header.loggedIn') : t('header.intranetText'),
            Icon: Intranet,
        });
    }

    const mainMenu = main.map((item) => {
        return {...item, forceExpanded: item.itemId === currentMegaId};
    });

    useEffect(() => {
        const body = document.querySelector('body');
        const isMobileMenu = body.classList.contains('js-state-mobile-menu');
        if(mobileMenu && !isMobileMenu) {
            body.classList.add('js-state-mobile-menu');
        } else if(!mobileMenu && isMobileMenu) {
            body.classList.remove('js-state-mobile-menu');
        }
    }, [mobileMenu]);

    const hasCTA = !_.isEmpty(CTA);

    const classes = classNames(
        s['Header'],
        {[s['Header--MobileMenu']]: mobileMenu},
        {[s['Header--MegaMenu']]: currentMegaId !== null},
        {[s['Header--Local']]: isLocal},
        {[s['Header--Intranet']]: isIntranet},
        {[s['Header--HasCTA']]: hasCTA},
    );

    return (
        <header className={classes}>
            <div className={s['Header__Top']}>
                <div className={s['Header__Wrap']}>
                    {isLocal &&
                        <a
                            className={classNames(s['Header__Logo'], s['Header__Logo--Small'])}
                            href={mainUrl}
                            rel="home"
                        >
                            <NextImage
                                {...LogoSmall}
                                alt={t('header.toHRF')}
                            />
                        </a>
                    }

                    <div className={classNames(s['Header__Nav'], s['Header__Nav--Top'])}>
                        <Top
                            {...props}
                            includeBack={true}
                        />
                    </div>

                    <div className={classNames(s['Header__Nav'], s['Header__Nav--RightTop'])}>
                        <Nav
                            items={rightTop}
                            label={t('header.rightTop')}
                            modifier="RightTop"
                        />
                    </div>
                </div>
            </div>

            <div className={s['Header__Main']}>
                <div className={s['Header__Wrap']}>
                    {isLocal ? (
                        <a
                            className={s['Header__Local']}
                            href={siteUrl}
                            title={t('header.toStartpage')}
                        >
                            <div className={s['Header__LocalCounty']}>{localCounty}</div>
                            <div className={s['Header__LocalName']}>{localName}</div>
                        </a>
                    ) : (
                        <a
                            className={s['Header__Logo']}
                            href={siteUrl}
                            rel="home"
                        >
                            {isIntranet ? (
                                <NextImage
                                    {...Hornet}
                                    alt={t('header.toStartpage')}
                                />
                            ) : (
                                <NextImage
                                    {...Logo}
                                    alt={t('header.toStartpage')}
                                />
                            )}
                        </a>
                    )}

                    <div className={classNames(s['Header__Nav'], s['Header__Nav--Main'])}>
                        <Nav
                            items={mainMenu}
                            label={t('header.main')}
                            modifier="Main"
                            currentId={currentId}
                            parentIds={parentIds}
                            onClickHandler={(e, {itemId, children}) => {
                                if(!_.isEmpty(children)) {
                                    e.preventDefault();
                                    const newId = currentMegaId === itemId ? null : itemId;
                                    setCurrentMegaId(newId);
                                }
                            }}
                        />
                    </div>

                    <div className={classNames(s['Header__Nav'], s['Header__Nav--RightMain'])}>
                        <RightMain
                            searchUrl={searchUrl}
                            cartUrl={cartUrl}
                            cartCount={cartCount}
                        />
                    </div>

                    <div className={classNames(s['Header__Nav'], s['Header__Nav--CTA'])}>
                        <NavCTA {...props} />
                    </div>

                    <button
                        className={s['Header__Toggle']}
                        onClick={() => setMobileMenu(!mobileMenu)}
                    >
                        <span className={'sr-only'}>{t('header.toggle')}</span>
                        <Hamburger />
                    </button>
                </div>
            </div>

            <div className={s['Header__MobileMenu']}>
                <div className={s['Header__MobileWrap']}>
                    <div className={classNames(s['Header__MobileNav'], s['Header__MobileNav--Main'])}>
                        <Nav
                            items={main}
                            label={t('header.main')}
                            modifier="Mobile"
                            currentId={currentId}
                            parentIds={parentIds}
                            allowChildren={true}
                            hideLevel={-1}
                            toggleChildren={true}
                        />
                    </div>
                    <div className={classNames(s['Header__MobileNav'], s['Header__MobileNav--Top'])}>
                        <Top {...props} />
                    </div>
                </div>
            </div>

            <MegaMenu
                items={main}
                currentId={currentId}
                currentMegaId={currentMegaId}
            />
        </header>
    );
};

Header.propTypes = {
    currentId: PropTypes.string,
    parentIds: PropTypes.array,
    main: PropTypes.array,
    top: PropTypes.array,
    CTA: PropTypes.array,
    myPages: PropTypes.object,
    searchUrl: PropTypes.string,
    cartUrl: PropTypes.string,
    cartCount: PropTypes.number,
    languages: PropTypes.array,
    mypagesUrl: PropTypes.string,
    intranetUrl: PropTypes.string,
    mainUrl: PropTypes.string,
    siteUrl: PropTypes.string,
    localCounty: PropTypes.string,
    localName: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    isLocal: PropTypes.bool,
    isIntranet: PropTypes.bool,
};

Header.defaultProps = {
    currentId: null,
    parentIds: null,
    main: [],
    top: [],
    CTA: [],
    myPages: {},
    searchUrl: '',
    cartUrl: '',
    cartCount: 0,
    languages: [],
    mypagesUrl: '',
    intranetUrl: '',
    mainUrl: '',
    siteUrl: '',
    localCounty: '',
    localName: '',
    isLoggedIn: false,
    isLocal: false,
    isIntranet: false,
};

const Top = ({top, mainUrl, currentId, includeBack}) => {
    const {t} = useTranslation();

    let leftTop = [...top];
    if(includeBack && !_.isEmpty(mainUrl)) {
        leftTop = [{
            url: mainUrl,
            title: t('header.backToHRF'),
        }, ...top];
    }

    return (
        <Nav
            items={leftTop}
            label={t('header.top')}
            modifier="Top"
            currentId={currentId}
        />
    );
};

Top.propTypes = {
    top: PropTypes.array,
    mainUrl: PropTypes.string,
    currentId: PropTypes.string,
    includeBack: PropTypes.bool,
};

Top.defaultProps = {
    top: [],
    mainUrl: '',
    currentId: null,
    includeBack: false,
};

const NavCTA = ({CTA}) => {
    const {t} = useTranslation();

    return (
        <Nav
            items={CTA}
            label={t('header.CTA')}
            modifier="CTA"
        />
    );
};

NavCTA.propTypes = {
    CTA: PropTypes.array,
};

NavCTA.defaultProps = {
    CTA: [],
};

const RightMain = ({searchUrl, cartUrl, cartCount}) => {
    const {t} = useTranslation();

    return (
        <nav
            className={s['Header__RightMain']}
            aria-label={t('header.rightMain')}
        >
            <SearchItem url={searchUrl} />

            {!_.isEmpty(cartUrl) &&
                <CartItem url={cartUrl} count={cartCount} />
            }
        </nav>
    );
};

RightMain.propTypes = {
    searchUrl: PropTypes.string,
    cartUrl: PropTypes.string,
    cartCount: PropTypes.number,
};

RightMain.defaultProps = {
    searchUrl: '',
    cartUrl: '',
    cartCount: 0,
};

const CartItem = ({url, count}) => {
    const {t} = useTranslation();

    // This has to have the same markup as woocommerce_add_to_cart_fragments hook in backend
    return (
        <a className="woocommerce-cart-link" href={url} id="header-cart">
            <span className={'sr-only'}>{t('header.cart')}</span>
            <Cart />

            {(count > 0) &&
                <span className="cart-count">
                    {count}
                    <span className={'sr-only'}>{t('header.cartProducts')}</span>
                </span>
            }
        </a>
    );
};

CartItem.propTypes = {
    url: PropTypes.string,
    count: PropTypes.number,
};

CartItem.defaultProps = {
    url: '',
    count: 0,
};

const SearchItem = ({url}) => {
    const {t} = useTranslation();
    return (
        <a className={classNames(s['Header__Item'], s['Header__Item--Search'])} href={url}>
            <span className={'sr-only'}>{t('header.search')}</span>
            <Search />
        </a>
    );
};

SearchItem.propTypes = {
    url: PropTypes.string,
};

SearchItem.defaultProps = {
    url: '',
};

const MegaMenu = ({items, currentId, currentMegaId}) => {
    const {t} = useTranslation();

    if(_.isEmpty(items)) {
        return null;
    }

    const item = _.find(items, {itemId: currentMegaId});
    const children = _.get(item, 'children', []);
    const name = _.get(item, 'title', '');
    if(_.isEmpty(children)) {
        return null;
    }

    return (
        <div className={s['Header__Mega']}>
            <div className={s['Header__Wrap']}>
                <Nav
                    currentId={currentId}
                    items={children}
                    label={t('header.mega', {name: name})}
                    modifier="Mega"
                    allowChildren={true}
                />
            </div>
        </div>
    );
};

MegaMenu.propTypes = {
    items: PropTypes.array,
    currentId: PropTypes.string,
    currentMegaId: PropTypes.number,
};

MegaMenu.defaultProps = {
    items: [],
    currentId: null,
    currentMegaId: null,
};

export default Header;
