import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { postGravityformsSubmission } from '../../api/wordpress';
import Snippet from 'Components/Snippet';
import s from './Gravityforms.module.scss';

const Gravityforms = ({
    innerHTML = '',
    identifier = 'gf',
    attrs = {},
    onConfirmation = () => {},
}) => {
    const { t } = useTranslation();

    const { formId } = attrs;

    const rootRef = useRef();

    const [confirmation, setConfirmation] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const defaultError = t('gravityforms.errors.default');
    const validationError = t('gravityforms.errors.validation');
    const defaultConfirmation = t('gravityforms.defaultConfirmation');

    useEffect(() => {
        const form = document.querySelector(`form#gform_${formId}`);

        const showErrors = (validation) => {
            rootRef.current.scrollIntoView({behavior: 'smooth'});

            form.classList.add('gform_validation_error');

            Object.keys(validation).forEach((key) => {
                const value = validation[key];
                const fieldId = `field_${formId}_${key}`;
                const field = form.querySelector(`#${fieldId}`);
                field.classList.add('gfield_error');

                const error = document.createElement('div');
                error.id = `validation_message_${fieldId}`;
                error.innerHTML = value;
                error.classList.add('gfield_description', 'validation_message', 'gfield_validation_message');
                field.appendChild(error);
            });
        };

        const resetForm = () => {
            setErrorMessage('');
            setConfirmation('');

            const spinner = form.querySelector('.gform_ajax_spinner');
            if(spinner) {
                spinner.style.display = '';
            }

            form.classList.remove('gform_validation_error');

            const errors = form.querySelectorAll('.gfield_error');
            if(errors && errors.length > 0) {
                errors.forEach(field => {
                    field.classList.remove('gfield_error');
                    field.querySelector('.gfield_validation_message').remove();
                });
            }
        };

        const showConfirmation = (type, message, redirect) => {
            onConfirmation();

            const confirmType = type || message.type;
            const confirmRedirect = redirect || message.link;
            if (confirmType && confirmRedirect && confirmType === 'redirect') {
                if (typeof window !== 'undefined') {
                    window.location.replace(confirmRedirect);
                    return false;
                }
            }
            setConfirmation(message || defaultConfirmation);
            rootRef.current.scrollIntoView({behavior: 'smooth'});
        };

        const handleSubmit = async (e) => {
            e.preventDefault();

            resetForm();

            const apiUrl = `/wp-json/gf/v2/forms/${formId}/submissions`;
            const formData = new FormData(e.target);
            try {
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        Accept: 'application/json',
                    },
                });
                // Allow 400 response since it is returned for validation error
                if (response.status > 400 && !response.ok) {
                    throw new Error(`Response status: ${response.status}`);
                }

                const data = await response.json();
                if (data) {
                    const {
                        confirmation_message,
                        confirmation_type,
                        confirmation_redirect,
                        is_valid,
                        validation_messages,
                    } = data;
                    if (is_valid && (confirmation_message || confirmation_redirect)) {
                        showConfirmation(confirmation_type, confirmation_message, confirmation_redirect);
                    } else if (validation_messages) {
                        setErrorMessage(validationError);
                        showErrors(validation_messages);
                    } else if (response.request.responseURL !== apiUrl) {
                        showConfirmation('redirect', '', response.request.responseURL);
                    } else {
                        setErrorMessage(defaultError);
                    }
                } else {
                    setErrorMessage(defaultError);
                }
            } catch (error) {
                console.error('API submission error:', error);
                setErrorMessage(defaultError);
            } finally {
                const spinner = form.querySelector('.gform_ajax_spinner');
                if(spinner) {
                    spinner.style.display = 'none';
                }
            }
        };

        if(form) {
            form.addEventListener('submit', handleSubmit);
        }

        return () => {
            if (form) {
                form.removeEventListener('submit', handleSubmit);
            }
        };
    }, [innerHTML]);

    return (
        <div className={s['Root']} ref={rootRef}>
            <div className={s['Root__Form']}>
                {!!errorMessage &&
                    <div className={s['Root__Error']}>{errorMessage}</div>
                }

                {!!confirmation ? (
                    <div
                        className={s['Root__Confirmation']}
                        dangerouslySetInnerHTML={{ __html: confirmation }}
                    />
                ) : (
                    <Snippet
                        type="gravityforms"
                        html={innerHTML}
                    />
                )}
            </div>
        </div>
    );
};

Gravityforms.propTypes = {
    attrs: PropTypes.object,
    identifier: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    innerHTML: PropTypes.string,
};

export default Gravityforms;
