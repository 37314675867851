import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useTranslation} from 'next-i18next';
import _ from 'lodash';
import {sanitizeText} from 'utils/html';
import s from './Breadcrumbs.module.scss';

const Breadcrumbs = ({items}) => {
    const {t} = useTranslation();
    return (
        <div className={s['Breadcrumbs']} aria-label={t('breadcrumbs.label')}>
            <nav className={s['Breadcrumbs__Nav']}>
                <ul className={s['Breadcrumbs__List']}>
                    {items.map((item, index) => (
                        <li className={s['Breadcrumbs__Item']} key={index}>
                            <Link
                                {...item}
                                isStart={index === 0}
                                isCurrent={index === items.length - 1}
                            />
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
};

Breadcrumbs.propTypes = {
    items: PropTypes.array,
};

Breadcrumbs.defaultProps = {
    items: [],
};

const Link = ({title, url, isCurrent, isStart}) => {
    const {t} = useTranslation();

    const sanitizedTitle = sanitizeText(title);

    const display = isStart && _.isEmpty(title) ? t('breadcrumbs.start') : sanitizedTitle;
    if(isCurrent) {
        return (
            <span
                className={classNames(s['Breadcrumbs__Link'], s['Breadcrumbs__Link--Current'])}
                aria-current="page"
                dangerouslySetInnerHTML={{__html: display}}
            />
        );
    }
    return (
        <a
            className={s['Breadcrumbs__Link']}
            href={url}
            dangerouslySetInnerHTML={{__html: display}}
        />
    );
};

Link.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    isCurrent: PropTypes.bool,
    isStart: PropTypes.bool,
};

Link.defaultProps = {
    title: '',
    url: '',
    isCurrent: false,
    isStart: false,
};

export default Breadcrumbs;
