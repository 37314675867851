import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'next-i18next';
import classNames from 'classnames';
import Copy from 'utils/copyToClipboard';
import Button from 'Components/Button';
import s from './CopyToClipboard.module.scss';

const CopyToClipboard = ({children, className, text, modifier}) => {
    const {t} = useTranslation();

    const [copied, setCopied] = useState(false);

    useEffect(() => {
        // For hiding and resetting copied state, also times with css for fading out
        if(copied) {
            setTimeout(() => {
                setCopied(false);
            }, 5000);
        }
    }, [copied]);

    const classes = classNames(
        className,
        'CopyToClipboard',
        {[s['CopyToClipboard--Copied']]: copied},
    );

    return (
        <Button
            className={classes}
            modifier={modifier}
            onClick={() => [
                setCopied(true),
                Copy(text),
            ]}
        >
            {children}

            {copied &&
                <span className={s['CopyToClipboard__Tooltip']}>{t('copyToClipboard.copied')}</span>
            }
        </Button>
    );
};

CopyToClipboard.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    text: PropTypes.string.isRequired,
    modifier: PropTypes.string,
};

CopyToClipboard.defaultProps = {
    children: '',
    className: '',
    text: '',
    modifier: '',
};

export default CopyToClipboard;
