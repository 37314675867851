// Set 0 as expire to store as session instead
const setCookie = (name, value, days = 0, path = '/') => {
    const d = new Date();
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = days > 0 ? d.toUTCString() : '0';
    document.cookie = `${name}=${value};expires=${expires};path=${path}`;
};

const getCookie = function(name) {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts.length == 2) return parts.pop().split(';').shift();
};

export { setCookie, getCookie };
