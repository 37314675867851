const isOS = () => {
    return navigator.userAgent.match(/ipad|iphone/i);
};
const isClipboard = () => {
    return navigator.clipboard;
};

const fallback = (text, container = document.body, className = '') => {
    const textArea = document.createElement('textArea');
    textArea.value = text;

    if(className !== '') {
        textArea.classList.add(className);
    }

    container.appendChild(textArea);

    if (isOS()) {
        const range = document.createRange();
        range.selectNodeContents(textArea);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
    } else {
        textArea.select();
    }

    const copy = document.execCommand('copy');
    container.removeChild(textArea);
    return copy;
};

const CopyToClipboard = (text, container = document.body, className = '') => {
    if (!isClipboard) {
        return new Promise((resolve) => {
            resolve(fallback(text, container, className));
        });
    } else {
        return navigator.clipboard.writeText(text).then(function() {
            return true;
        });
    }
};

export default CopyToClipboard;