import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'next-i18next';
import NextLink from 'next/link';
import NextImage from 'next/image';
import classNames from 'classnames';
import _ from 'lodash';
import External from 'Assets/svg/external.svg';
import Copy from 'Assets/svg/copy.svg';
import Logo from 'Assets/img/logo-hrf-small.svg';
import SvenskInsamlingskontroll from 'Assets/img/svenskinsamlingskontroll.svg';
import TryggtGivande from 'Assets/img/tryggtgivande.svg';
import CopyToClipboard from 'Components/CopyToClipboard';
import s from './Footer.module.scss';

const Footer = ({footer, socialMedia, service}) => {
    const {t} = useTranslation();

    const firstFooter = footer.slice(0, 4);
    const secondFooter = footer.slice(4);

    return (
        <footer className={s['Footer']}>
            <div className={s['Footer__Container']}>
                <div className={s['Footer__Content']}>
                    <NextLink
                        className={s['Footer__Logo']}
                        href="/"
                        rel="home"
                        aria-label={t('footer.toStartpage')}
                    >
                        <NextImage
                            {...Logo}
                            alt={t('footer.toStartpage')}
                        />
                    </NextLink>

                    {!_.isEmpty(firstFooter) &&
                        <Nav
                            items={firstFooter}
                            label={t('footer.firstFooter')}
                            modifier="Footer"
                            className={s['Footer']}
                            allowChildren={true}
                        />
                    }

                    {!_.isEmpty(secondFooter) &&
                        <Nav
                            items={secondFooter}
                            label={t('footer.secondFooter')}
                            modifier="Footer"
                            className={s['Footer']}
                            allowChildren={true}
                        />
                    }
                </div>
            </div>
            <div className={s['Footer__Footer']}>
                <div className={s['Footer__Wrap']}>
                    <div className={s['Footer__Columns']}>
                        <div className={s['Footer__Column']}>
                            {!_.isEmpty(socialMedia) &&
                                <Nav
                                    items={socialMedia}
                                    label={t('footer.socialMedia')}
                                    modifier="SocialMedia"
                                    hasIcon={true}
                                />
                            }
                        </div>
                        <div className={s['Footer__Column']}>
                            {!_.isEmpty(service) &&
                                <Nav
                                    items={service}
                                    label={t('footer.service')}
                                    modifier="Service"
                                />
                            }
                        </div>
                        <div className={s['Footer__Column']}>
                            <div className={s['Footer__Logos']}>
                                <NextImage
                                    {...SvenskInsamlingskontroll}
                                    alt="Svensk insamlingskontroll"
                                />
                                <NextImage
                                    {...TryggtGivande}
                                    alt="Tryggt givande"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    footer: PropTypes.array,
    socialMedia: PropTypes.array,
    service: PropTypes.array,
};

Footer.defaultProps = {
    footer: [],
    socialMedia: [],
    service: [],
};

const Nav = ({
    items,
    label,
    modifier,
    currentId,
    allowChildren,
    hasIcon,
    onClickHandler,
}) => {
    if(_.isEmpty(items)) {
        return null;
    }

    const classes = classNames(
        s['Footer__Nav'],
        {[s[`Footer__Nav--${modifier}`]]: modifier},
    );
    return (
        <nav className={classes} aria-label={label}>
            <List
                items={items}
                currentId={currentId}
                allowChildren={allowChildren}
                hasIcon={hasIcon}
                onClickHandler={onClickHandler}
            />
        </nav>
    );
};

Nav.propTypes = {
    currentId: PropTypes.string,
    items: PropTypes.array,
    label: PropTypes.string,
    modifier: PropTypes.string,
    allowChildren: PropTypes.bool,
    hasIcon: PropTypes.bool,
    onClickHandler: PropTypes.func,
};

Nav.defaultProps = {
    currentId: null,
    items: [],
    label: '',
    modifier: '',
    allowChildren: false,
    hasIcon: false,
    onClickHandler: null,
};

const List = ({
    items,
    currentId,
    isFirstLevel,
    allowChildren,
    hideChildren,
    hasIcon,
    onClickHandler,
}) => {
    if(_.isEmpty(items)) {
        return null;
    }

    const classes = classNames(
        s['Footer__List'],
        {[s['Footer__List--Children']]: !isFirstLevel},
        {[s['Footer__List--Hide']]: hideChildren},
    );

    return (
        <ul className={classes}>
            {items.map((item, index) => {
                const children = _.get(item, 'children', []);
                return (
                    <li className={s['Footer__Item']} key={index}>
                        <Link
                            {...item}
                            currentId={currentId}
                            hasIcon={hasIcon}
                            onClickHandler={(e) => {
                                if(onClickHandler !== null) {
                                    onClickHandler(e, item);
                                }
                            }}
                        />

                        {allowChildren &&
                            <List
                                items={children}
                                currentId={currentId}
                                isFirstLevel={false}
                                allowChildren={allowChildren}
                                hideChildren={!isFirstLevel}
                                hasIcon={hasIcon}
                                onClickHandler={onClickHandler}
                            />
                        }
                    </li>
                );
            })}
        </ul>
    );
};

List.propTypes = {
    items: PropTypes.array,
    currentId: PropTypes.string,
    isFirstLevel: PropTypes.bool,
    allowChildren: PropTypes.bool,
    hideChildren: PropTypes.bool,
    hasIcon: PropTypes.bool,
    onClickHandler: PropTypes.func,
};

List.defaultProps = {
    items: [],
    currentId: '',
    isFirstLevel: true,
    allowChildren: false,
    hideChildren: false,
    hasIcon: false,
    onClickHandler: null,
};

const Link = ({
    id,
    currentId,
    title,
    description,
    text,
    url,
    target,
    rel,
    attrTitle,
    extraClasses,
    image,
    isLarge,
    hasIcon,
    Icon,
    onClickHandler,
}) => {
    const {t} = useTranslation();

    const titleClass = hasIcon ? s['Footer__LinkText'] + ' sr-only' : s['Footer__LinkText'];

    const isCopy = extraClasses.includes('copy');

    const classes = classNames(
        s['Footer__Link'],
        {[s['Footer__Link--NoLink']]: _.isEmpty(url)},
        {[s['Footer__Link--HasIcon']]: hasIcon || Icon},
        {[s['Footer__Link--HasImage']]: !_.isEmpty(image)},
        {[s['Footer__Link--Current']]: !_.isEmpty(currentId) && id === currentId},
        {[s['Footer__Link--Large']]: isLarge},
        extraClasses.map((name) => [s[`Footer__Link--${_.upperFirst(name)}`]]),
    );

    if(_.isEmpty(title) && _.isEmpty(text)) {
        return null;
    }

    if(isCopy) {
        return (
            <CopyToClipboard
                className={classes}
                text={text.replaceAll(' ', '')}
            >
                <h2 className={titleClass}>
                    <span dangerouslySetInnerHTML={{__html: title}} />
                </h2>

                <div
                    className={s['Footer__Text']}
                    dangerouslySetInnerHTML={{__html: text}}
                />

                <span className={s['Footer__Copy']}>
                    <span className={'sr-only'}>{t('footer.clickTo')}</span>
                    {t('footer.copy')}

                    <Copy />
                </span>
            </CopyToClipboard>
        );
    }

    if(_.isEmpty(url)) {
        return (
            <div className={classes}>
                <h2 className={titleClass}>
                    <span dangerouslySetInnerHTML={{__html: title}} />
                </h2>

                {!_.isEmpty(description) &&
                    <span
                        className={s['Footer__Description']}
                        dangerouslySetInnerHTML={{__html: description}}
                    />
                }

                {!_.isEmpty(text) &&
                    <div
                        className={s['Footer__Text']}
                        dangerouslySetInnerHTML={{__html: text}}
                    />
                }
            </div>
        );
    }

    return (
        <a
            className={classes}
            href={url}
            target={target}
            rel={rel}
            title={attrTitle}
            onClick={(e) => onClickHandler(e)}
        >
            {isLarge && _.isEmpty(image) &&
                <div className={s['Footer__Shell']} />
            }

            <span className={titleClass}>
                <span dangerouslySetInnerHTML={{__html: title}} />
                <span className={s['Footer__External']}>
                    <External />
                </span>
            </span>

            {!_.isEmpty(description) &&
                <span
                    className={s['Footer__Description']}
                    dangerouslySetInnerHTML={{__html: description}}
                />
            }

            {!_.isEmpty(text) &&
                <div
                    className={s['Footer__Text']}
                    dangerouslySetInnerHTML={{__html: text}}
                />
            }

            {Icon &&
                <Icon />
            }
        </a>
    );
};

Link.propTypes = {
    id: PropTypes.string,
    itemId: PropTypes.number.isRequired,
    currentId: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string.isRequired,
    target: PropTypes.string,
    rel: PropTypes.string,
    attrTitle: PropTypes.string,
    extraClasses: PropTypes.array,
    image: PropTypes.object,
    isLarge: PropTypes.bool,
    hasIcon: PropTypes.bool,
    Icon: PropTypes.elementType,
    onClickHandler: PropTypes.func,
};

Link.defaultProps = {
    id: '',
    itemId: 0,
    currentId: null,
    title: '',
    description: '',
    text: '',
    url: '',
    target: null,
    rel: null,
    attrTitle: null,
    extraClasses: [],
    image: null,
    isLarge: false,
    hasIcon: false,
    Icon: null,
    onClickHandler: null,
};

export default Footer;
